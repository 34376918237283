<template lang="html">
  <div style="height: 100%">
    <AnimalInfo :animal-page="true" :animalId="Number(this.$route.params.animalId)"/>
  </div>
</template>

<script>
import AnimalInfo from "@/components/animals/AnimalInfo.vue";

export default {
  components: {
    AnimalInfo
  },
}
</script>